<template>
	<ui-panel>
		<ui-panel-title><div class="subtitle">Lottery Entry Details</div></ui-panel-title>
		<ui-panel-body>
			<ui-form ref="form">
				<ui-text-box-field
					readonly
					label="Date"
					:value="block.dateof">
				</ui-text-box-field>

				<!--
				<div class="radio-list vert">
					<ui-radio 
						label="Only play selected course" 
						:value="true" 
						:data="reservation.only_play_selected"
						@input="updateField('only_play_selected', $event)">
					</ui-radio>

					<ui-radio 
						label="Play any course" 
						:value="false" 
						:data="reservation.only_play_selected"
						@input="updateField('only_play_selected', $event)">
					</ui-radio>
				</div>
				-->


				<template v-if="!block.lottery_is_event">
					
					<template v-if="!block.disable_lottery_window">
						<ui-select-field
							label="Earliest time"
							:items="timeOptions"
							:value="reservation.earliest_time"
							@input="updateField('earliest_time', $event)">
						</ui-select-field>

						<ui-select-field
							label="Latest time"
							:items="timeOptions"
							:value="reservation.latest_time"
							@input="updateField('latest_time', $event)">
						</ui-select-field>
					</template>

					<ui-select-field
						label="Desired time"
						empty-text="Select Time"
						empty-value=""
						:rules="rules.desiredTime"
						:items="timeOptions"
						:value="reservation.desired_time"
						@input="updateField('desired_time', $event)">
					</ui-select-field>
				</template>

			</ui-form>
		</ui-panel-body>
	</ui-panel>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import filters from 'igolf/shared/filters';

export default {
	data()
	{
		return {
			//ignoreRestrictions: false,

			rules: {
			
				desiredTime: [ v => !!v || 'Please select Desired Time' ],
			}
		}
	},
	computed: {
		...mapGetters('teesheet', ['courseList', 'teetimePlayerOptions']),
		...mapState({
			reservation: state => state.teesheet.reservation,
			block: state => state.teesheet.reservationBlock,
			teetimes: state => state.teesheet.teetimes,
		}),

		timeOptions()
		{
			var times = [];
			var options = [];
			
			// go over all teetime entry ids
			for(var t of this.teetimes.times)
			{
				// get row mapped to this id
				let row = this.teetimes.rows[t];

				// only return distinct time options that belong to this block
				if (times.indexOf(row.time) == -1 && row.block && row.block.id == this.block.id)
				{
					times.push(row.time);
					options.push({
						text: filters.filters.to12Hour(this.teetimes.rows[t].time),
						value: this.teetimes.rows[t].time
					})
				}
			}

			return options;
		},
			
	},

	/*watch: {
		teetimePlayerOptions()
		{
			if (this.teetimePlayerOptions.length == 1)
				this.updateField('user_id', this.teetimePlayerOptions[0].value);
		}
	},*/

	methods: {
		...mapMutations('teesheet', ['updateReservationDetails']),

		updateField(field, val)
		{
			
			this.updateReservationDetails({ [field]: val });
			//setting earliest_time same as desired_time if windos disable is enabled.
			if(this.block.disable_lottery_window && field == 'desired_time'){
				this.updateReservationDetails({ ['earliest_time']: val });
			}
		},

		validate()
		{
			// return true; 
			return this.$refs.form.validate();
		}
	}
}
</script>